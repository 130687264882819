<template>
  <section id="penyusunan-surat">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Lihat Rekomendasi Direktur -->
              <span v-if="props.column.field == 'status'">
                <b-badge
                  :variant="statusVariant((props.row.total_program === props.row.kegiatan_done) ? 'Clearance Selesai' : 'Menunggu Clearance Selesai').class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant((props.row.total_program === props.row.kegiatan_done) ? 'Clearance Selesai' : 'Menunggu Clearance Selesai').title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field == 'surat_rekomendasi'">
                <b-button
                  :to="{ name: 'list-surat-rekomendasi', params: { id: props.row.clearance_id } }"
                  variant="outline-secondary"
                  size="sm"
                  class="m-25"
                >
                  View Daftar Surat Rekomendasi</b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="userData.group_id > 59 && userData.group_id < 70"
                  v-b-tooltip.hover
                  :to="{ name: 'buat-surat-rekomendasi', params: { id: props.row.clearance_id } }"
                  variant="primary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Buat Surat Rekomendasi"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Daftar Item Belanja"
                  @click="openKomponen(props.row)"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      no-close-on-backdrop
      hide-footer
      centered
      size="xl"
      :title="modalTitle"
    >
      <b-card-text>
        <!-- table -->
        <vue-good-table
          :columns="subColumns"
          :rows="app_rows"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'nama'"
              class="text-wrap"
            >
              <b-badge
                variant="light-secondary"
              >
                {{ props.row.domain }}
              </b-badge>
              <span class="d-block">
                {{ props.row.nama }}
              </span>
            </span>
            <span v-else-if="props.column.field === 'id'">
              {{ props.row.id }}
            </span>
            <span v-else-if="props.column.field === 'anggaran'">
              {{ Number(props.row.anggaran).toLocaleString() }}
            </span>
            <!-- Lihat Rekomendasi Direktur -->
            <div v-else-if="props.column.field == 'rekomendasi'">
              <div v-if="props.row.rekomendasi.continue.length !== 0">
                <h5>Dilanjutkan</h5>
                <p
                  v-for="(reko, keyd) in props.row.rekomendasi.continue"
                  :key="keyd"
                >
                  {{ reko }}
                </p>
              </div>
              <div v-if="props.row.rekomendasi.postpone.length !== 0">
                <h5>Tidak Dilanjutkan</h5>
                <p
                  v-for="(reko, keyd) in props.row.rekomendasi.postpone"
                  :key="keyd"
                >
                  {{ reko }}
                </p>
              </div>
            </div>
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                v-if="props.row.exist === 'YES'"
                variant="light-warning"
                class="mb-50"
              >Sudah pernah ditambahkan<br>di surat sebelumnya
              </b-badge>
              <b-badge
                v-else
                variant="light-secondary"
                class="mb-50"
              >Belum pernah ditambahkan<br>di surat sebelumnya
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','50']"
                  class="mx-1"
                  @change="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import store from '@/store'
import vSelect from 'vue-select'
import {
  BBadge, BRow, BButton, BCol, BCard, BInputGroup, BInputGroupAppend, BPagination,
  BFormGroup, BFormInput, VBTooltip, VBModal, BAlert, BCardText, BFormSelect,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BRow,
    BCol,
    BCardText,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 69) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      fileSurat: '',
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
      },
      columns: [
        {
          thClass: 'text-center',
          label: 'Pemda',
          field: 'instansi',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Instansi',
          },
        },
        {
          thClass: 'text-center',
          label: 'No. Surat Permohonan',
          field: 'letter_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Nomor Surat',
          },
        },
        {
          thClass: 'text-center',
          label: 'Total Program',
          type: 'number',
          field: 'total_program',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Kegiatan Selesai',
          type: 'number',
          field: 'kegiatan_done',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Total Surat Rekomendasi',
          type: 'number',
          field: 'surat_rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'clearance_id',
            asc_desc: 'asc',
          },
        ],
        start: 0,
        length: 30,
      },
      rows: [],
      searchTerm: '',
      subColumns: [
        {
          thClass: 'text-center',
          label: 'No',
          field: 'id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Unit Kerja',
          field: 'unit_kerja',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          width: '175px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          width: '175px',
          field: 'nama',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Anggaran',
          field: 'anggaran',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi & Catatan',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          field: 'status',
          sortable: false,
        },
      ],
      app_rows: [],
      subSearchTerm: '',
      master_data_instansi: '',
      fileMode: '',
      fileOptions: [
        { text: 'Kominfo', value: 'kominfo' },
        { text: 'MenpanRB', value: 'menpan' },
      ],
      file1: null,
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
      modalTitle: '',
      doOpenModal: false,
      listClearance: [],
      pageLength: 20,
      number: 0,
    }
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'susunSurat' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'susunSurat')
    this.getReference()
  },
  methods: {
    openKomponen(appData) {
      this.modalTitle = `Daftar Item Belanja Surat No : ${appData.letter_number}`
      this.doOpenModal = true
      this.app_rows = []

      const params = {
        token: localStorage.getItem('userToken'),
        clearance_id: appData.clearance_id,
      }

      this.$http.get('/surat/get', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listClearance = res.data.data
            this.prepData()
            this.$refs['modal-komponen'].show()
          }
        })
        .catch(error => {
          this.app_rows = []
          this.listClearance = []
          this.number = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    prepData() {
      this.number = 0
      this.app_rows = []
      if (this.listClearance.length !== 0) {
        this.listClearance.map(value => {
          if (value.aplikasi.length !== 0) {
            value.aplikasi.map(apps => {
              this.number += 1
              const appData = apps
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Aplikasi'

              this.app_rows.push(appData)
              return true
            })
          }
          if (value.infrastruktur.length !== 0) {
            value.infrastruktur.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Infrastruktur'

              this.app_rows.push(appData)
              return true
            })
          }
          if (value.domain_data.length !== 0) {
            value.domain_data.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Data'

              this.app_rows.push(appData)
              return true
            })
          }
          if (value.non_teknis.length !== 0) {
            value.non_teknis.map(infs => {
              this.number += 1
              const appData = infs
              appData.id = this.number
              appData.nama_kegiatan = value.nama_kegiatan
              appData.unit_kerja = value.unit_kerja
              appData.domain = 'Non Teknis'

              this.app_rows.push(appData)
              return true
            })
          }
          return true
        })
      }
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Surat Selesai': {
          title: 'Surat Selesai',
          class: 'light-success',
        },
        'Clearance Selesai': {
          title: 'Clearance Telah Selesai',
          class: 'light-info',
        },
        'Menunggu Clearance Selesai': {
          title: 'Clearance Belum Selesai Semua',
          class: 'light-secondary',
        },
        /* eslint-enable key-spacing */
      }

      return statusColor[status]
    },
    doFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = []
      if (this.master_data_instansi) {
        filterz.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        filterz.push({
          column: key,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.rows = []
      this.$http.post(`/surat/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
